exports.components = {
  "component---src-pages-activate-jsx": () => import("./../../../src/pages/activate.jsx" /* webpackChunkName: "component---src-pages-activate-jsx" */),
  "component---src-pages-assist-jsx": () => import("./../../../src/pages/assist.jsx" /* webpackChunkName: "component---src-pages-assist-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-ai-jsx": () => import("./../../../src/pages/legal/ai.jsx" /* webpackChunkName: "component---src-pages-legal-ai-jsx" */),
  "component---src-pages-legal-cookies-jsx": () => import("./../../../src/pages/legal/cookies.jsx" /* webpackChunkName: "component---src-pages-legal-cookies-jsx" */),
  "component---src-pages-legal-index-jsx": () => import("./../../../src/pages/legal/index.jsx" /* webpackChunkName: "component---src-pages-legal-index-jsx" */),
  "component---src-pages-legal-privacy-jsx": () => import("./../../../src/pages/legal/privacy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-jsx" */),
  "component---src-pages-legal-terms-jsx": () => import("./../../../src/pages/legal/terms.jsx" /* webpackChunkName: "component---src-pages-legal-terms-jsx" */),
  "component---src-pages-verify-jsx": () => import("./../../../src/pages/verify.jsx" /* webpackChunkName: "component---src-pages-verify-jsx" */)
}

