import { useForm as useFormReactHookForm } from 'react-hook-form';

function useForm({ resolver } = {}) {
	const methods = useFormReactHookForm({
		mode: 'onBlur',
		resolver,
	});

	return methods;
}

export default useForm;
