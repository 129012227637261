import PropTypes from 'prop-types';
import React from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const Input = ({
	autoComplete,
	className,
	classNameGroup,
	classNameLabel,
	defaultValue,
	description,
	disabled,
	floatingLabel = true,
	max,
	min,
	name,
	onChange,
	placeholder,
	readOnly,
	type,
	value,
}) => {
	const {
		formState: { errors },
		register,
	} = useFormContext();
	const error = errors && errors[name] ? errors[name] : null;
	const { onChange: registerOnChange, ...registerData } = register(name);

	const handleOnChange = (event) => {
		if (onChange) event = onChange(event);
		registerOnChange(event);
	};

	if (!name) return <></>;
	if (type == 'hidden') {
		return (
			<Form.Control
				defaultValue={defaultValue}
				type={type}
				value={value}
				{...register(name)}
			/>
		);
	}

	return floatingLabel == true ? (
		<>
			<Form.Group className={classNameGroup} controlId={name}>
				<FloatingLabel
					className={classNameLabel}
					controlId={name}
					label={placeholder}
				>
					<Form.Control
						aria-describedby={`${name}_text`}
						autoComplete={autoComplete}
						className={className}
						defaultValue={defaultValue}
						disabled={disabled}
						isInvalid={!!error}
						max={max}
						min={min}
						onChange={handleOnChange}
						placeholder={placeholder}
						readOnly={readOnly}
						size="lg"
						type={type}
						value={value}
						{...registerData}
					/>
				</FloatingLabel>

				{description && (
					<Form.Text id={`${name}_text`} muted>
						{description}
					</Form.Text>
				)}

				<Form.Control.Feedback type="invalid">
					{error && <>{error.message}</>}
				</Form.Control.Feedback>
			</Form.Group>
		</>
	) : (
		<>
			<Form.Group className={classNameGroup} controlId={name}>
				<Form.Control
					aria-describedby={`${name}_text`}
					autoComplete={autoComplete}
					className={className}
					defaultValue={defaultValue}
					isInvalid={!!error}
					max={max}
					min={min}
					onChange={handleOnChange}
					placeholder={placeholder}
					size="lg"
					type={type}
					value={value}
					{...registerData}
				/>

				{description && (
					<Form.Text id={`${name}_text`} muted>
						{description}
					</Form.Text>
				)}

				<Form.Control.Feedback type="invalid">
					{error && <>{error.message}</>}
				</Form.Control.Feedback>
			</Form.Group>
		</>
	);
};

Input.defaultProps = {
	classNameGroup: 'mb-3',
	classNameLabel: 'mb-3',
};

Input.propTypes = {
	autoComplete: PropTypes.string,
	className: PropTypes.string,
	classNameGroup: PropTypes.string,
	classNameLabel: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	description: PropTypes.string,
	disabled: PropTypes.bool,
	floatingLabel: PropTypes.bool,
	max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	name: PropTypes.string,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.string,
};

export default Input;
