import PropTypes from 'prop-types';
import React from 'react';
import { FormProvider } from 'react-hook-form';

const Form = ({ children, methods, onSubmit }) => {
	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
		</FormProvider>
	);
};

Form.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.func.isRequired,
		PropTypes.node.isRequired,
	]),
	methods: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default Form;
