import { navigate } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Form from '../components/form';
import Input from '../components/form/input';
import Submit from '../components/form/submit';
import Framework from '../components/framework';
import NavTop from '../components/framework/nav-top';
import Seo from '../components/framework/seo';
import Suspense from '../components/framework/suspense';
import useForm from '../hooks/use_form';
import useMutate from '../hooks/use_mutate';
import authentication from '../libs/authentication';

const Assist = () => {
	const formMethods = useForm();
	const { trigger: triggerAccountPrepare } = useMutate(
		'authentication.accountPrepare'
	);
	const { trigger: triggerAccountVerify } = useMutate(
		'authentication.accountVerify'
	);

	const onSubmit = async (data) => {
		const result_prepare = await triggerAccountPrepare({
			data: {
				assist_password: data?.password,
				username: data?.username,
			},
			method: 'assist',
		});
		if (!result_prepare?.data?.sid) return;

		const result_verify = await triggerAccountVerify({
			sid: result_prepare.data.sid,
		});
		if (!result_verify?.data?.token) return;

		authentication.signIn(result_verify.data.token);
		navigate('/', { replace: true });
	};

	return (
		<Framework>
			<Seo title="Assist" />
			<NavTop></NavTop>

			<Container className="mt-5 pt-5 signin signin-assist">
				<Row className="justify-content-center">
					<Col lg="4">
						<div className="my-5">
							<Suspense tags={{ component: 'Assist' }}>
								<div
									className={`p-lg-5 p-3 my-4 rounded-4 bg-white signin-container`}
								>
									<Form methods={formMethods} onSubmit={onSubmit}>
										<Input
											className="text-center"
											floatingLabel={false}
											name="username"
											placeholder="Username"
											size="md"
										/>
										<Input
											className="text-center"
											floatingLabel={false}
											name="password"
											placeholder="Password"
											size="md"
											type="password"
										/>
										<Submit className="w-100" name="Continue" variant="dark" />
									</Form>
								</div>
							</Suspense>
						</div>
					</Col>
				</Row>
			</Container>
		</Framework>
	);
};

export default Assist;
