import PropTypes from 'prop-types';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const Submit = ({
	className = 'd-block w-100',
	name,
	rightSection,
	size = 'lg',
	useIsDirty = true,
	useValidation = true,
	variant = 'dark',
}) => {
	const {
		formState: { isDirty, isSubmitting, isValid, isValidating },
	} = useFormContext();

	let disabled = isSubmitting;
	if (!disabled && useIsDirty) {
		disabled = !isDirty;
	}
	if (!disabled && useValidation) {
		disabled = !isValid || isValidating;
	}

	return (
		<Button
			className={className}
			disabled={disabled}
			size={size}
			type="submit"
			variant={variant}
		>
			{isSubmitting && (
				<>
					<Spinner
						animation="border"
						aria-hidden="true"
						as="span"
						role="status"
						size="sm"
					/>
					<span className="visually-hidden">Loading...</span>
				</>
			)}
			{!isSubmitting && <>{name}</>}
			{rightSection && rightSection}
		</Button>
	);
};

Submit.propTypes = {
	className: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	rightSection: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	size: PropTypes.string,
	useIsDirty: PropTypes.bool,
	useValidation: PropTypes.bool,
	variant: PropTypes.string,
};

export default Submit;
